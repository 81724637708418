export default [
    { name: "normal" },
    { name: "clarendon" },
    { name: "gingham" },
    { name: "moon" },
    { name: "lark" },
    { name: "reyes" },
    { name: "juno" },
    { name: "slumber" },
    { name: "aden" },
    { name: "perpetua" },
    { name: "mayfair" },
    { name: "rise" },
    { name: "hudson" },
    { name: "valencia" },
    { name: "xpro2" },
    { name: "willow" },
    { name: "lofi" },
    { name: "inkwell" },
    { name: "nashville" }
];
